import React from "react";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";

export function QuickUserToggler() {
  return (
    <>
      <UserProfileDropdown />
    </>
  );
}
